<template>
  <div>
    <template v-if="!examineVisible && !detailVisible">
      <div class="card" :style="{ height: height }">
        <div class="left">
          <el-button
            type="primary"
            plain
            @click="handleExportAll"
            style="height: 40px"
            :loading="exportLoading"
            icon="el-icon-upload2"
            >导出</el-button
          >
          <el-button
            type="primary"
            style="height: 40px"
            @click="handlePrint"
            :loading="printAllLoading"
            >打印</el-button
          >
        </div>
        <div class="line"></div>
        <div class="middle">
          <el-form :model="form" :inline="true" class="form">
            <!-- <el-form-item label="门店">
                <el-select
                  v-model="form.hierarchy_id"
                  filterable
                  :disabled="userInfo.hierarchy_type_id === 40"
                  :multiple="userInfo.hierarchy_type_id === 30 ? true : false"
                  collapse-tags
                >
                  <template v-for="item in merchantList">
                    <el-option
                      :value="item.hierarchy_id"
                      :label="item.hierarchy_name"
                      :key="item.hierarchy_id"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item> -->
              <el-form-item label="单据日期">
                <el-date-picker
                  v-model="form.create_time"
                  type="daterange"
                  align="right"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="款项名称">
                <el-select v-model="form.stream_type_id" clearable="" filterable="">
                  <template v-for="item in streamTypeList">
                    <el-option :label="item.name" :value="item.id" :key="item.id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="客户">
                <el-select v-model="form.customer_id" clearable="" filterable="" placeholder="请输入姓名或电话" remote :remote-method="remoteMethod" :loading="selectLoading">
                  <template v-for="item in userList">
                    <el-option :label="item.realname + '-' + item.username" :value="item.client_user_id" :key="item.client_user_id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="单据编号">
                <el-input placeholder="请输入" v-model="form.sundry_bill_number"></el-input>
              </el-form-item>

              <el-form-item label="业务员">
                <el-select v-model="form.salesman_store_id" clearable="" filterable="">
                  <template v-for="item in employee">
                    <el-option :label="item.realname" :value="item.store_user_id" :key="item.store_user_id"></el-option>
                  </template>
                </el-select>
              </el-form-item>

          </el-form>
          <div style="margin: 0 10px 0 auto">
            <el-button
              @click="handleHeight"
              style="
                width: 40px;
                height: 40px;
                line-height: 36px;
                text-align: center;
                padding: 0;
              "
            >
              <i class="el-icon-caret-bottom" v-if="height === '40px'"></i>
              <i class="el-icon-caret-top" v-else></i>
            </el-button>
          </div>
          <div style="display: flex">
            <el-button type="primary"  @click="onSubmit" style="height: 40px"
              >搜索</el-button
            >
            <el-button  @click="resetForm" style="height: 40px;">重置</el-button>
          </div>
        </div>
      </div>
      <div class="model">
        <el-button icon="el-icon-plus" type="primary" style="margin: 10px 0 0 10px;" @click="handleAdd">新增收入</el-button>
        <div class="table">
          <el-table
            ref="table-01"
            :data="tableData.list"
            stripe
            border
            v-loading="loading"
            height="660"
            @row-dblclick="handleDalclick"
            :row-class-name="handleRowIndex"
            show-summary
            :summary-method="getSummaries"
          >
            <template v-for="(item, index) in columnList">
              <el-table-column
                align="center"
                :key="index"
                :prop="item.field_alias"
                :label="item.field_text"
                :width="item.t_width"
                :filters="item.filed_status"
              >
                <template slot-scope="scope">
                  <template v-if="item.field_alias === 'status_name'">
                    <div v-if="scope.row.status === 30" style="color: #67C23A;">
                      {{scope.row.status_name}}
                    </div>
                    <div v-else-if="scope.row.status === 40" style="color: #F56C6C;">
                      {{scope.row.status_name}}
                    </div>
                    <div v-else>
                      {{scope.row.status_name}}
                    </div>
                  </template>
                  <template v-else>
                    {{
                      scope.row[item.field_alias]
                        ? scope.row[item.field_alias]
                        : "--"
                    }}
                  </template>
                </template>
              </el-table-column>
            </template>
            <el-table-column align="center" field_alias="operation" label="操作" width="180px">
              <template slot-scope="scope">
                <div class="options">
                  <el-select
                    v-model="scope.row.select"
                    @change="handleChange($event, scope.row)"
                  >
                    <template v-if="scope.row.status === 20">
                      <el-option label="详情" :value="1"></el-option>
                      <el-option label="审核" :value="2"></el-option>
                      <el-option label="打印" :value="3"></el-option>
                    </template>
                    <template v-if="scope.row.status === 30">
                      <el-option label="详情" :value="1"></el-option>
                      <el-option label="打印" :value="3"></el-option>
                      <el-option label="解锁" :value="4" v-if="!noSockIds.includes(scope.row.sundry_bill_id)"></el-option>
                      <el-option label="编辑" :value="6" v-if="noSockIds.includes(scope.row.sundry_bill_id)"></el-option>
                      <el-option label="删除" :value="7" v-if="noSockIds.includes(scope.row.sundry_bill_id)"></el-option>
                    </template>
                    <template v-if="scope.row.status === 40">
                      <el-option label="详情" :value="1"></el-option>
                      <el-option label="打印" :value="3"></el-option>
                      <el-option label="失败原因" :value="5"></el-option>
                      <el-option label="编辑" :value="6"></el-option>
                      <el-option label="删除" :value="7"></el-option>
                    </template>
                  </el-select>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class='pagina'>
          <el-pagination
            background
            @size-change="handleSizeChange"
            layout='total, sizes, prev, pager, next, jumper'
            :total='tableData.total'
            :page-size='limit'
            :page-sizes="$store.state.pageSizes"
            @current-change='handleCurrentChange'
          ></el-pagination>
        </div>
      </div>
    </template>
    <el-dialog
      title="失败原因"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>{{currRow.fail_remark}}</span>
    </el-dialog>
    <Examine v-if="examineVisible" @onBack="handleBack" />
    <Detail v-if="detailVisible" @onBack="handleBack" :formType="formType" :row="currRow"  />
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import {
  getMerchantListReq,
} from "@/api/goods/historyInv/index";
import { incomeListReq, getstreamTypeListReq, getUserListReq, getEmployeeReq, getIsLockReq, deleReq, getIncomeBillInfoReq, exportIncomeReq } from '@/api/statistics/finance/otherIncome';
import Examine from './Examine';
import Detail from './Detail';
import storage from "good-storage";
import LocalPrint from "@/components/localPrint/Index.vue";
import numeral from 'numeral';

export default {
  data() {
    return {
      selectLoading: false,
      localPrintVisible: false,
      title: '',
      printData: {},
      printRows: [],
      dialogVisible: false,
      currRow: {},
      noSockIds: [],
      formType: '', // detail详情  add新增  edit编辑
      employee: [], // 员工列表
      userList: [], // 客户列表
      streamTypeList: [], // 款项列表
      detailVisible: false,
      examineVisible: false,
      page: 1,
      limit: this.$store.state.pageSizes[0],
      loading: false,
      offsetHeight: "106px",
      height: "40px",
      exportLoading: false,
      printAllLoading: false,
      form: {},
      merchantList: [], // 门店列表
      userInfo: {},
      columnList: [
        { field_text: "序号", field_alias: "index", width: 10},
        { field_text: "单据编号", field_alias: "sundry_bill_number", t_width: 220, width: 18 },
        { field_text: "订单类型", field_alias: "type_name", width: 14 },
        { field_text: "单据日期", field_alias: "bill_time", t_width: 170, width: 14 },
        { field_text: "门店", field_alias: "shop_name", width: 14 },
        { field_text: "客户", field_alias: "membername", width: 14 },
        { field_text: "业务员", field_alias: "store_user_name", width: 14 },
        { field_text: "款项名称", field_alias: "stream_name", width: 14 },
        { field_text: "收款金额", field_alias: "amount", width: 14 },
        { field_text: "备注", field_alias: "remark", width: 14 },
        { field_text: "审核状态", field_alias: "status_name", width: 14 },
        { field_text: "创建人", field_alias: "creator", width: 14 },
        { field_text: "创建时间", field_alias: "create_time", t_width: 170, width: 14 },
        { field_text: "审核人", field_alias: "checker", width: 14 },
        { field_text: "审核时间", field_alias: "check_time", t_width: 170, width: 14 },
      ],
      tableData: {
        list: [],
        total: 0,
      },
      columnList2: [
        { field_text: "序号", field_alias: "index", width: 10 },
        { field_text: "支付方式", field_alias: "pay_channel_name", width: 66 },
        { field_text: "金额", field_alias: "money", width: 66 },
        { field_text: "备注", field_alias: "remark", width: 66 },
      ],
    };
  },
  components: {
    Examine,
    Detail,
    LocalPrint,
  },
  mounted() {
    if (this.$route.params.key) {
      this.formType = 'detail';
      this.detailVisible = true;
      this.currRow.sundry_bill_id = this.$route.params.key;
    }
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantList();
    // 获取款项列表
    this.getstreamTypeList();
    // 获取客户
    // this.getUserList();
    // 获取员工
    this.getEmployee();
    // 获取列表
    this.getList();
  },
  updated () {
　　this.$nextTick(() => {
　　　　this.$refs['table-01'].doLayout();
　　})
  },
  methods: {
    remoteMethod(key) {
      if (!key) {
        return;
      }
      this.getUserList(key);
    },
    handlePrint() {
      if (!this.tableData.list.length) {
        this.$message.warning('没有可打印的数据');
        return;
      }
      this.printRows = this.columnList;
      this.printData = JSON.parse(JSON.stringify(this.tableData));
      let total = this.tableData.list.reduce((pre, curr) => {
        return Number(curr.amount) + pre;
      }, 0);
      total = numeral(total).format('0.00');
      this.printData.list.push({
        index: '合计',
        amount: total,
      })
      console.log(this.printData);
      this.title = '其它收入列表';
      this.localPrintVisible = true;
    },
    // 合计
    getSummaries(param) {
      const { data } = param;
      const sums = [];
      sums[0] = "合计";
      sums[8] = data.reduce((pre, curr) => {
        return pre + Number(curr.amount);
      }, 0);
      sums[8] = numeral(sums[8]).format("0.00");
      return sums;
    },
    handleClose() {
      this.dialogVisible = false;
      this.currRow.select = '';
    },
    handleChange(val, row) {
      this.currRow = row;
      // 详情
      if (val === 1) {
        this.formType = 'detail';
        this.detailVisible = true;
      }
      // 审核
      if (val === 2) {
        this.formType = 'examine';
        this.detailVisible = true;
      }
      // 打印
      if (val === 3) {
        getIncomeBillInfoReq({
          sundry_bill_id: row.sundry_bill_id,
        })
          .then((res) => {
            row.select = '';
            if (res.code === 1) {
              const data = res.data;
              this.title = '其它收入单';
              let top = [];
              const arr1 = [
                { txt: "收入单号", value: data.info.sundry_bill_number, width: "30%" },
                { txt: "单据日期", value: data.info.bill_time, width: "20%" },
                { txt: "业务员", value: data.info.store_user_name, width: "20%" },
                { txt: "款项名称", value: data.info.stream_name, width: "20%" },
              ];
              top.push(arr1);
              const arr2 = [
                { txt: "客户", value: data.info.membername, width: "30%" },
                { txt: "创建人", value: data.info.creator, width: "20%" },
                { txt: "创建时间", value: data.info.create_time, width: "30%" },
              ];
              top.push(arr2);
              let bottom = [];
              const arr3 = [
                { txt: "备注", value: data.info.remark, width: "100%" },
              ];
              bottom.push(arr3);
              const arr4 = [
                { txt: "审核人", value: data.info.checker, width: "24%" },
                { txt: "审核时间", value: data.info.check_time, width: "24%" },
              ];
              bottom.push(arr4);
              this.printRows = this.columnList2;
              data.list.forEach((item, index) => {
                item.index = index + 1;
              })
              this.printData = {
                top,
                list: data.list,
                bottom,
              }
              console.log(this.printData);
              this.localPrintVisible = true;
            }
          })
          .catch(() => {
            row.select = '';
          });
      }
      // 解锁
      if (val === 4) {
        getIsLockReq({
          source: 'sundrybill',
          primary_key_id: row.sundry_bill_id,
        })
          .then((res) => {
            this.$message.success('解锁成功');
            this.currRow = {};
            this.noSockIds.push(row.sundry_bill_id);
            row.select = '';
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          });
      }
      // 失败原因
      if (val === 5) {
        this.dialogVisible = true;
      }
      // 编辑
      if (val === 6) {
        this.formType = 'edit';
        this.detailVisible = true;
      }
      // 删除
      if (val === 7) {
         this.$confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleReq({
            sundry_bill_id: row.sundry_bill_id,
          })
            .then((res) => {
              row.select = '';
              this.currRow = {};
              if (res.code === 1) {
                 this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.getList();
              }
            })
        }).catch(() => {
          row.select = '';
          this.currRow = {};
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    // 新增
    handleAdd() {
      this.detailVisible = true;
      this.formType = 'add';
    },
    handleRowIndex({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    // 获取员工列表
    getEmployee() {
      getEmployeeReq()
        .then((res) => {
          if (res.code === 1) {
            this.employee = res.data;
          }
        });
    },
    // 获取客户列表
    getUserList(search) {
      this.selectLoading = true;
      getUserListReq({
        page: 1,
        limit: 99,
        search,
      })
        .then((res) => {
          this.selectLoading = false;
          if (res.code === 1) {
            this.userList = res.data.list;
          }
        })
        .catch(() => {
          this.selectLoading = false;
        });
    },
    // 获取款项列表
    getstreamTypeList() {
      getstreamTypeListReq({
        type: 10,
      })
        .then((res) => {
          if (res.code === 1) {
            this.streamTypeList = res.data;
          }
        })
    },
    // 获取列表
    getList() {
      this.loading = true;
      incomeListReq({
        ...this.form,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleBack(getList) {
      this.examineVisible = false;
      this.detailVisible = false;
      this.currRow.select = '';
      if (getList) {
        this.getList();
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    handleSizeChange(limit) {
      this.limit = limit;
      this.getList();
    },
    handleDalclick(row) {
      this.currRow = row;
      this.formType = 'detail';
      this.detailVisible = true;
    },
    resetForm() {
      this.form = {};
      this.page = 1;
      this.getList();
    },
    // 搜索
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    handleHeight() {
       this.height = this.height === "40px" ? this.offsetHeight : "40px";
    },
    handleExportAll() {
      this.exportLoading = true;
      exportIncomeReq({
        ...this.form,
        export: 1,
        page: this.page,
        limit: this.limit,
        function_code: 'sundrybill',
      })
        .then((res) => {
          this.exportLoading = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `其它收入列表.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    getMerchantList() {
      getMerchantListReq().then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
          // 如果是门店
          if (this.userInfo.hierarchy_type_id === 40) {
            this.form.hierarchy_id = res.data[0].hierarchy_id;
          }
        }
      });
    },
  },
}
</script>

<style lang="less" scoped>
.table {
  border: 1px solid #ddd;
  margin-top: 10px;
}
.model {
  background: white;
}
.left {
  display: flex;
}
.right {
  display: flex;
}
// .form {
//   display: flex;
//   /deep/ .el-form-item {
//     margin-bottom: 0px !important;
//   }
// }
.card {
  transition: all 0.3s;
  display: flex;
}
.middle {
  display: flex;
  overflow: hidden;
}
</style>
