<template>
  <div v-loading="loading">
    <div class="card">
      <el-page-header
        @back="goBack"
        :content="'其它收入' + typeTxt"
      ></el-page-header>
    </div>
    <div class="model">
      <el-form
        style="margin-top: 20px"
        :modle="form"
        :inline="true"
        label-width="70px"
      >
        <el-form-item label="单据日期">
          <el-date-picker
            v-if="formType === 'add' || formType === 'edit'"
            v-model="form.bill_time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
          <div class="item" v-else>
            {{ detailInfo.bill_time }}
          </div>
        </el-form-item>
        <el-form-item label="款项名称">
          <el-select
            v-model="form.stream_type_id"
            clearable=""
            filterable=""
            v-if="formType === 'add' || formType === 'edit'"
          >
            <template v-for="item in streamTypeList">
              <el-option
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </template>
          </el-select>
          <div class="item" v-else>
            {{ detailInfo.stream_name }}
          </div>
        </el-form-item>
        <el-form-item label="客户">
          <el-select
            v-model="form.customer_id"
            clearable=""
            filterable=""
            v-if="formType === 'add' || formType === 'edit'"
            remote
            :remote-method="remoteMethod"
            :loading="selectLoading"
            placeholder="请输入姓名或电话"
          >
            <template v-for="item in userList">
              <el-option
                :label="item.realname + ' ' + item.username"
                :value="item.client_user_id"
                :key="item.client_user_id"
              ></el-option>
            </template>
          </el-select>
          <div class="item" v-else>
            {{ detailInfo.membername }}
          </div>
        </el-form-item>
        <el-form-item label="业务员">
          <el-select
            v-model="form.salesman_store_id"
            clearable=""
            filterable=""
            v-if="formType === 'add' || formType === 'edit'"
          >
            <template v-for="item in employee">
              <el-option
                :label="item.realname"
                :value="item.store_user_id"
                :key="item.store_user_id"
              ></el-option>
            </template>
          </el-select>
          <div class="item" v-else>
            {{ detailInfo.store_user_name }}
          </div>
        </el-form-item>
        <el-form-item label="门店">
          <!-- <el-select
              v-model="form.hierarchy_id"
              filterable
              :disabled="userInfo.hierarchy_type_id === 40"
              :multiple="userInfo.hierarchy_type_id === 30 ? true : false"
              collapse-tags
            >
              <template v-for="item in merchantList">
                <el-option
                  :value="item.hierarchy_id"
                  :label="item.hierarchy_name"
                  :key="item.hierarchy_id"
                ></el-option>
              </template>
            </el-select> -->
          <div class="item">{{ userInfo.hierarchy_name }}</div>
        </el-form-item>
        <el-form-item label="收入单号">
          <div class="item" style="width: 200px">
            {{ detailInfo.sundry_bill_number || "--" }}
          </div>
        </el-form-item>
        <el-form-item label="创建人">
          <div class="item">{{ userInfo.realname }}</div>
        </el-form-item>
        <el-form-item label="创建时间">
          <div class="item" v-if="formType === 'add'">
            {{ form.create_time }}
          </div>
          <div class="item" v-else>{{ detailInfo.create_time }}</div>
        </el-form-item>
        <el-form-item label="单据状态">
          <div class="item" style="color: #f56c6c">
            <span v-if="formType === 'add'">待审核</span>
            <span v-else>{{ detailInfo.status_name }}</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="divider"></div>
      <div class="buttons" v-if="formType === 'add' || formType === 'edit'">
        <el-button type="primary" @click="handleAddRow">新增行</el-button>
        <el-button icon="el-icon-plus" type="primary" @click="addVisible = true">款项名称</el-button>
        <!-- <span style="margin-left: 10px;">注意：按下<span style="color: #F56C6C;"> Enter键 </span>完成当前行录入。</span> -->
      </div>
      <!-- 表格 -->
      <div class="table">
        <el-table
          ref="table-01"
          :data="tableData"
          show-summary
          stripe
          border
          height="320"
          style="border-top: 1px solid #ddd; margin-top: 10px"
          :row-class-name="handleRowIndex"
          :summary-method="getSummaries"
        >
          <template v-for="(item, index) in columnList">
            <el-table-column
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.width"
              :filters="item.filed_status"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias === 'pay_channel'">
                  <el-select
                    v-model="scope.row.pay_channel"
                    v-if="scope.row.pay_channelEdit"
                    clearable=""
                    filterable=""
                  >
                    <template v-for="item in pay_channel">
                      <el-option
                        :label="item.name"
                        :value="item.pay_type"
                        :key="item.pay_type"
                      ></el-option>
                    </template>
                  </el-select>
                  <span v-else>{{ scope.row.pay_channel_name || "--" }}</span>
                </template>
                <template v-else-if="item.field_alias === 'money'">
                  <el-input
                    v-if="scope.row.moneyEdit"
                    placeholder="请输入"
                    v-model="scope.row.money"
                    type="number"
                    class="no-number"
                    @focus="handleFocus"
                  ></el-input>
                  <span v-else>{{ scope.row.money }}</span>
                </template>
                <template v-else-if="item.field_alias === 'remark'">
                  <el-input
                    v-if="scope.row.remarkEdit"
                    placeholder="请输入备注"
                    v-model="scope.row.remark"
                    @focus="handleFocus"
                  ></el-input>
                  <span v-else>{{ scope.row.remark }}</span>
                </template>
                <template v-else>
                  {{ scope.row[item.field_alias] }}
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            field_alias="operation"
            label="操作"
            width="180px"
            v-if="formType === 'add' || formType === 'edit'"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                style="color: #f56c6c"
                @click="handleDel(scope.row)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 表单 -->
      <el-form :modle="form2" style="margin-top: 20px">
        <div
          style="display: flex"
          v-if="row.status === 30 || row.status === 40"
        >
          <el-form-item label="审核人" label-width="80px">
            <div class="item">{{detailInfo.checker || '--'}}</div>
          </el-form-item>
          <el-form-item label="审核时间" label-width="80px">
            <div class="item">{{detailInfo.check_time || '--'}}</div>
          </el-form-item>
        </div>
        <el-form-item label="备注" label-width="80px">
          <el-input
            placeholder="请输入"
            v-if="formType === 'add' || formType === 'edit'"
            type="textarea"
            :rows="5"
            v-model="form2.remark"
          ></el-input>
          <span v-else>{{form2.remark}}</span>
        </el-form-item>
      </el-form>
      <!-- 按钮 -->
      <div class="btns" v-if="formType === 'add'">
        <el-button type="primary" @click="handleSave" :loading="saveLoading"
          >提交审核</el-button
        >
        <el-button type="primary" plain @click="goBack">取消</el-button>
      </div>
      <div class="btns" v-if="formType === 'edit'">
        <el-button type="primary" @click="handleEdit" :loading="saveLoading"
          >提交审核</el-button
        >
        <el-button type="primary" plain @click="goBack">取消</el-button>
      </div>
      <div class="btns" v-if="formType === 'examine'">
        <el-button type="primary" @click="handleChangeStatus(30)"
          >审核通过</el-button
        >
        <el-button type="primary" plain @click="dialogVisible = true"
          >审核不通过</el-button
        >
      </div>
    </div>
    <el-dialog
      title="审核不通过原因"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form :model="form">
        <el-form-item label="原因说明" required="">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入审核不通过原因说明"
            v-model="fail_remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增款项"
      :visible.sync="addVisible"
      width="30%"
      :before-close="handleClose">
      <el-form :model="addForm" label-width="80px">
        <el-form-item label="类型名称" l>
          <el-input placeholder="请输入" v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="收支方式">
          <el-radio-group v-model="addForm.type">
            <el-radio :label="10">收入</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            :active-value="10"
            :inactive-value="20"
            v-model="addForm.status">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddStreamType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMerchantListReq } from "@/api/goods/historyInv/index";
import {
  getstreamTypeListReq,
  getUserListReq,
  getEmployeeReq,
  getPayChannelCtlReq,
  createIncomeReq,
  getIncomeBillInfoReq,
  updateStatusIncomeReq,
  updateIncomeReq,
  addStreamTypeReq,
} from "@/api/statistics/finance/otherIncome";
import storage from "good-storage";
import moment from "moment";
import numeral from "numeral";

export default {
  data() {
    return {
      selectLoading: false,
      addForm: {
        status: 10,
        type: 10,
      },
      addVisible: false,
      fail_remark: "", // 失败原因说明
      updateLoading: false,
      detailInfo: {},
      dialogVisible: false,
      loading: false,
      saveLoading: false,
      pay_channel: [], // 支付方式
      timer: null, // 定时器
      employee: [], // 员工列表
      userList: [], // 客户列表
      streamTypeList: [], // 款项列表
      form2: {},
      // formType: 'edit', // detail详情  add新增  edit编辑
      userInfo: {},
      form: {},
      merchantList: [], // 门店列表
      tableData: [
        {
          pay_channelEdit: true, // 是否能编辑的标识
          moneyEdit: true, // 是否能编辑的标识
          remarkEdit: true, // 是否能编辑的标识
          pay_channel: "",
          money: "",
          remark: "",
        },
      ], // 表格数据
      columnList: [
        { field_text: "序号", field_alias: "index", width: 100 },
        { field_text: "支付方式", field_alias: "pay_channel" },
        { field_text: "金额", field_alias: "money" },
        { field_text: "备注", field_alias: "remark", width: 1000 },
      ],
    };
  },
  props: {
    formType: {
      type: String,
    },
    row: {
      type: Object,
    },
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  computed: {
    typeTxt() {
      let txt = "";
      if (this.formType === "detail") {
        txt = "详情";
      } else if (this.formType === "add") {
        txt = "新增";
      } else if (this.formType === "edit") {
        txt = "编辑";
      } else if (this.formType === "examine") {
        txt = "审核";
      }
      return txt;
    },
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantList();
    // 获取款项列表
    this.getstreamTypeList();
    // 获取员工
    this.getEmployee();
    // 获取支付方式
    this.getPayChannelCtl();
    // 获取当前日期
    if (this.formType === "add") {
      const bill_time = moment().format("YYYY-MM-DD");
      const create_time = moment().format("YYYY-MM-DD HH:mm:ss");
      this.form = {
        ...this.form,
        bill_time,
        create_time,
      };
    }

    // 创建时间更新
    if (this.formType === "add") {
      this.timer = setInterval(() => {
        const create_time = moment().format("YYYY-MM-DD HH:mm:ss");
        this.form = {
          ...this.form,
          create_time,
        };
      }, 1000);
    }
    // 详情
    if (
      this.formType === "detail" ||
      this.formType === "examine" ||
      this.formType === "edit"
    ) {
      this.tableData = [];
      this.getIncomeBillInfo();
      this.getUserList(this.row.membername);
    }
  },
  updated () {
　　this.$nextTick(() => {
　　　　this.$refs['table-01'].doLayout();
　　})
  },
  methods: {
    remoteMethod(key) {
      if (!key) {
        return;
      }
      this.getUserList(key);
    },
    handleFocus(e) {
      e.currentTarget.select();
    },
    // 新增款项名称
    handleAddStreamType() {
      addStreamTypeReq({
        ...this.addForm,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('新增成功');
            this.getstreamTypeList();
            this.addVisible = false;
          }
        });
    },
    handleSubmit() {
      this.updateStatusIncome(40, this.fail_remark);
    },
    handleChangeStatus(status) {
      this.$confirm("确定要审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.updateStatusIncome(status);
      });
    },
    updateStatusIncome(status, fail_remark) {
      this.updateLoading = true;
      updateStatusIncomeReq({
        sundry_bill_id: this.row.sundry_bill_id,
        status,
        fail_remark,
      })
        .then((res) => {
          this.updateLoading = false;
          if (res.code === 1) {
            this.$emit("onBack", "getList");
          }
        })
        .catch(() => {
          this.updateLoading = false;
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.addVisible = false;
    },
    // 获取详情
    getIncomeBillInfo() {
      this.loading = true;
      getIncomeBillInfoReq({
        sundry_bill_id: this.row.sundry_bill_id,
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            const info = res.data.info;
            this.tableData = res.data.list;
            if (this.formType === "edit") {
              this.tableData = this.tableData.map((item) => {
                return {
                  ...item,
                  pay_channelEdit: true, // 是否能编辑的标识
                  moneyEdit: true, // 是否能编辑的标识
                  remarkEdit: true, // 是否能编辑的标识
                };
              });
            }
            this.detailInfo = info;
            this.form = {
              ...this.form,
              bill_time: info.bill_time,
              stream_type_id: info.stream_type_id,
              customer_id: info.customer_id,
              salesman_store_id: info.salesman_store_id,
            };
            this.form2 = {
              ...this.form2,
              remark: info.remark,
            };
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 编辑
    handleEdit() {
      this.saveLoading = true;
      let data = {
        ...this.form,
        remark: this.form2.remark,
        amountList: this.tableData,
        sundry_bill_id: this.row.sundry_bill_id,
      }
      updateIncomeReq({
        ...data,
      })
        .then((res) => {
          this.saveLoading = false;
          if (res.code === 1) {
            this.$emit("onBack", "getList");
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    // 提交审核
    handleSave() {
      this.saveLoading = true;
      let data = {
        ...this.form,
        remark: this.form2.remark,
        amountList: this.tableData,
      }
      createIncomeReq({
        ...data,
      })
        .then((res) => {
          this.saveLoading = false;
          if (res.code === 1) {
            this.$emit("onBack", "getList");
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    // 新增行
    handleAddRow() {
      this.tableData.push({
        pay_channelEdit: true, // 是否能编辑的标识
        moneyEdit: true, // 是否能编辑的标识
        remarkEdit: true, // 是否能编辑的标识
        pay_channel: "",
        money: "",
        remark: "",
      });
    },
    // 支付方式列表
    getPayChannelCtl() {
      getPayChannelCtlReq({ status: 10, page: 1, limit: 99999 }).then((res) => {
        if (res.code === 1) {
          this.pay_channel = res.data;
        }
      });
    },
    handleRowIndex({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    // 获取员工列表
    getEmployee() {
      getEmployeeReq().then((res) => {
        if (res.code === 1) {
          this.employee = res.data;
        }
      });
    },
    // 获取客户列表
    getUserList(search) {
      this.selectLoading = true;
      getUserListReq({
        page: 1,
        limit: 99,
        search,
      })
        .then((res) => {
          this.selectLoading = false;
          if (res.code === 1) {
            this.userList = res.data.list;
          }
        })
        .catch(() => {
          this.selectLoading = false;
        });
    },
    // 获取款项列表
    getstreamTypeList() {
      getstreamTypeListReq({
        type: 10,
      }).then((res) => {
        if (res.code === 1) {
          this.streamTypeList = res.data;
        }
      });
    },
    // 合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      sums[0] = "合计";
      sums[2] = data.reduce((pre, curr) => {
        return pre + Number(curr.money);
      }, 0);
      sums[2] = numeral(sums[2]).format("0.00");
      return sums;
    },
    // 删除
    handleDel(row) {
      this.tableData = this.tableData.filter((item, index) => {
        return index + 1 !== row.index;
      });
    },
    goBack() {
      this.$emit("onBack");
    },
    // 获取门店
    getMerchantList() {
      getMerchantListReq().then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
          // 如果是门店
          if (this.userInfo.hierarchy_type_id === 40) {
            this.form.hierarchy_id = res.data[0].hierarchy_id;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.btns {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #ddd;
}
.item {
  width: 220px;
}
.model {
  background: white;
  padding: 10px;
  padding-bottom: 70px;
}
.divider {
  margin-bottom: 20px;
  height: 1px;
  background: #ddd;
}
</style>
